// src/components/Article.js
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

function Article(props) {

    const { organismeSlug, formationSlug, chapter, article } = useParams();


    const [content, setContent] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Appel API pour récupérer la liste des organismes
        fetch(
            'https://canide.fr/api/article/' +
            organismeSlug +
            '/' +
            formationSlug +
            '/' +
            chapter +
            '/' +
            article, {
            method: 'GET',
            headers: {
                'x-goog-id-token': props.token // Utilisez le nom d'en-tête approprié
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                // if (Object.keys(data.content).length === 0) {
                //     navigate("/blocked");
                // }
                setContent(data);
                console.log(data)
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, [organismeSlug, formationSlug, chapter, article]);


    if (!organismeSlug || !formationSlug || !chapter || !article) {
        navigate("/blocked");
    }

    // Use the slug to fetch the specific article or render it
    return (
        <Container>

            <Link to={"/formation/" + organismeSlug + "/" + formationSlug}>
                <Button className="m-2" variant="primary">
                    <KeyboardReturnIcon /> Retour à la formation
                </Button>
            </Link>

            <h1>{content.title}</h1>

            {
                content.content && Object.keys(content.content).map((key) => {
                    const item = content.content[key];
                    if (item.type === "title") {
                        return <h2>{item.content}</h2>
                    }
                    if (item.type === "title-2") {
                        return <h3>{item.content}</h3>
                    }
                    if (item.type === "download") {
                        return <a target="_blank" href={item.content}>Télécharger la ressource</a>
                    }
                    if (item.type === "paragraph") {
                        return <p>{item.content}</p>
                    }
                    if (item.type === "image") {
						console.log(item.content);
                        return <img src={item.content} className='w-100' />
                    }
                    if (item.type === "list") {
                        return <ul>{
                            item.content.map((list_item) => {
                                return <li>{list_item}</li>
                            })
                        }</ul>
                    }
                    if (item.type === "video") {
                        return Object.keys(item.variants).map((list_item) => {
                            const video_content = item.variants[list_item];
                            if (list_item === "youtube") {
                                return <iframe width="100%" height="315" src={"https://www.youtube.com/embed/" + video_content.uri} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share fullscreen;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="allowfullscreen" ></iframe>
                            }
                        })
                    }
                })
            }
        </Container>
    );
}

export default Article;