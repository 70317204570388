import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import Home from './views/Home';
import Layout from './views/Layout';
import Formation from './views/Formation';
import Article from './views/Article';
import Blocked from './views/Blocked';

function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [formations, setFormations] = useState([]);

  useEffect(() => {
    // Charger les informations de l'utilisateur depuis localStorage
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    if (storedToken) {
      setToken(storedToken);;
    }
  }, []);

  const responseMessage = (response) => {
    const decodedToken = jwtDecode(response.credential);
    setUser(decodedToken);
    setToken(response.credential);

    // Stocker les informations de l'utilisateur dans localStorage
    localStorage.setItem('user', JSON.stringify(decodedToken));
    localStorage.setItem('token', response.credential);
	
	// Appel API pour récupérer la liste des organismes
	fetch('https://canide.fr/api/formations', {
		method: 'GET',
		headers: {
			'x-goog-id-token': token // Utilisez le nom d'en-tête approprié
		}
	}).then(response => {
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}
		return response.json();
	}).then(data => {
		setFormations(data['formations']);
	}).catch(error => {
		console.error('Erreur lors de la récupération des données:', error);
	});
	
    console.log(decodedToken);
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const handleLogout = () => {
    // Effacer les informations de l'utilisateur et rediriger vers la page de connexion
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <>
      {user && user.email ? (
        <Layout user={user} setUser={setUser}>
          <Router>
            <Routes>
              <Route path="/" element={<Home token={token} />} />
              <Route path="/blocked" element={<Blocked />} />
              <Route path="/formation/:organismeSlug/:formationSlug" element={<Formation token={token} />} />
              <Route path="/article/:organismeSlug/:formationSlug/:chapter/:article" element={<Article token={token} />} />
              {/* <Route path="/about" element={<About />} />
            <Route path="/organisme/:organismeSlug" element={<Organisme />} />
            <Route path="*" element={<NotFound />} /> */}
            </Routes>
          </Router>
        </Layout>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'  // Takes full viewport height
        }}>
          <GoogleLogin auto_select onSuccess={responseMessage} onError={errorMessage} />
        </div>
      )}
    </>
  );
}

export default App;
