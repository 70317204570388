// src/components/Article.js
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Chapter from '../components/Formation/Chapter.';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

function Formation(props) {
    const { organismeSlug, formationSlug } = useParams();

    const [formation, setFormation] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Appel API pour récupérer la liste des organismes
        fetch('https://canide.fr/api/formation/' + organismeSlug + '/' + formationSlug, {
            method: 'GET',
            headers: {
                'x-goog-id-token': props.token // Utilisez le nom d'en-tête approprié
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {

                if (Object.keys(data.content).length === 0) {
                    navigate("/blocked");
                }
                setFormation(data);
                console.log(data)
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, [organismeSlug, formationSlug]);

    if (!organismeSlug || !formationSlug) {
        return <div>Formation non trouvée.</div>;
    }

    if (Object.keys(formation).length === 0) {
        return <div>Formation en cours de chargement ...</div>;
    }



    // Use the slug to fetch the specific article or render it
    return (
        <Container>
            <Link to={"/"}>
                <Button className="m-2" variant="primary">
                    <KeyboardReturnIcon /> Retour aux formations
                </Button>
            </Link>
            <div className='m-4 text-center'>
                <h1>{formation.name}</h1>
                <Image rounded className='w-75' alt={formation.slug} src={formation.img} />
            </div>
            <p>{formation.description}</p>
            {
                Object.keys(formation.content).map((key) => {
                    const item = formation.content[key];
                    return (
                        <Chapter
                            organisme_slug={organismeSlug}
                            formation_slug={formationSlug}
                            chapter_id={key}
                            name={item.name}
                            content={item}
                        />
                    );
                })
            }
        </Container>
    );
}

export default Formation;