import React, { useState, useEffect } from 'react';
import ThumbFormation from '../components/Home/ThumbFormation';
import { Col, Row, Badge, Button } from 'react-bootstrap';  // Import du Badge
import { Link, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import './Home.css';

const Home = (props) => {
    const [articles, setArticles] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Appel API pour récupérer la liste des organismes
        fetch('https://canide.fr/api/formations', {
            method: 'GET',
            headers: {
                'x-goog-id-token': props.token // Utilisez le nom d'en-tête approprié
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {

            if (Object.keys(data).length === 0) {
                navigate("/blocked");  // Use navigate directly here
            } else {
                setArticles(data['formations']);
            }

        }).catch(error => {
            console.error('Erreur lors de la récupération des données:', error);
        });
    }, [navigate, props.token]);  // Add navigate and props.token to the dependency array

    const columns = [
        {
            name: 'Image',
            selector: row => <img src={row.img} className='w-100' />,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Title',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Catégorie',
            selector: row => <Badge bg="secondary" className={row.category}>{row.category}</Badge>,  // Ajout du Badge ici
            sortable: true,
        },
        {
            name: 'Organisme',
            selector: row => <Badge bg="secondary" className={row.organisme_slug}>{row.organisme_slug}</Badge>,  // Ajout du Badge ici
            sortable: true,
        },
        {
            name: 'Lien',
            selector: row => <>
                <Link to={"/formation/" + row.organisme_slug + "/" + row.slug}>
                    <Button className="m-2" size="sm" variant="primary">
                        Voir la formation
                    </Button>
                </Link>
            </>,
            sortable: true,
        },
    ];

    const customStyles = {
        header: {
            style: {
                backgroundColor: '#333',
                color: '#fff',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#444',
                color: '#fff',
            },
        },
        headCells: {
            style: {
                color: '#fff',
            },
        },
        rows: {
            style: {
                backgroundColor: '#333',
                color: '#fff',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#555',
                color: 'white',
                cursor: 'pointer',
            },
        },
        pagination: {
            style: {
                backgroundColor: '#333',
                color: '#fff',
            },
        },
    };

    return (
        <DataTable
            title="Les formations"
            columns={columns}
            data={articles}
            customStyles={customStyles}  // Ajout des styles sombres ici
        />
    );
};

export default Home;
