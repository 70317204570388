// src/components/Layout.js
import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';

import './Layout.css';

const Layout = ({ user, setUser, children }) => {

    const handleLogout = () => {
        // onLogout(); // Appeler la fonction de déconnexion passée en prop
        // signOut(); // Déconnecter l'utilisateur de Google
        setUser(null)
        console.log("deco")
    };

    return (
        <Container fluid>
            <div className="d-flex flex-column min-vh-100">
                <header className="text-white p-3">
                    <Navbar bg="dark" data-bs-theme="dark">
                        <Navbar.Brand href="/">canide.fr</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">Accueil</Nav.Link>
                            </Nav>

                            {user && user.email ? (
                                <>
                                    <Button variant="outline-light" onClick={handleLogout}>
                                        Déconnexion
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}

                        </Navbar.Collapse>
                    </Navbar>

                    {user && user.email ? (
                        <>
                            <p className='text-center text-secondary'>Bonjour {user.given_name} ({user.email}) !</p>
                        </>
                    ) : (
                        <></>
                    )}
                </header>
                <main className="my-5 flex-grow-1">
                    <Container fluid>
                        {children}
                    </Container>
                </main>

                <footer className="text-white text-center p-3 mt-auto">
                    <div className="container">
                        <p>&copy; 2024 Canide.fr</p>
                    </div>
                </footer>
            </div>
        </Container >
    );
};

export default Layout;
