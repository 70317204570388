// src/components/Blocked.js
import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const Blocked = () => {
	return <div className="text-center">
		<h1 className='text-danger'>Accès non autorisé <ReportProblemIcon fontSize="large" /></h1>
		<p>Un rapport d'incident contenant votre adresse email a été transmis aux administrateurs.</p>

	</div>
};

export default Blocked;