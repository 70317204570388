// src/components/Article.js
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import './Chapter.css';


function Chapter(props) {

    return (
        <div>
            <h3>{props.name}</h3>
            <ListGroup className='mb-4'>
                {
                    Object.keys(props.content.content).map((key) => {
                        const item_bis = props.content.content[key];
                        return (
                            <ListGroup.Item key={key} className="d-flex justify-content-between align-items-center">
                                <Link to={`/article/${props.organisme_slug}/${props.formation_slug}/${props.chapter_id}/${key}`} className="w-100 d-flex flex-column flex-sm-row justify-content-between">
                                    <span>
                                        {(
                                            item_bis.available &&
                                            <Badge bg="success" className="ms-2"><EventAvailableIcon /></Badge>
                                        ) || <Badge bg="danger" className="ms-2"><EventBusyIcon /></Badge>
                                        }{' '}
                                        {item_bis.name}</span>
                                    <span className="mt-2 mt-sm-0">
                                        {
                                            item_bis.categories && item_bis.categories.map((category, index) => (
                                                <Badge key={index} className="ms-2">{category}</Badge>
                                            ))
                                        }
                                    </span>
                                </Link>
                            </ListGroup.Item>

                        );
                    })
                }
            </ListGroup>
        </div>
    );
}

export default Chapter;